import type { EmotionCache } from '@emotion/cache'
import type { AppProps } from 'next/app'
import type { NextPageWithLayout } from '@/types/app'

import React from 'react'
import Head from 'next/head'
import nProgress from 'nprogress'
import { Toaster } from 'react-hot-toast'
import { CssBaseline } from '@mui/material'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Router } from 'next/router'
import { SWRConfig } from 'swr'

import { APP_DISPLAY_NAME } from '@/configs/constants'
import { SettingsConsumer, SettingsProvider } from '@/contexts/settings-context'
import { createTheme } from '@/themes/theme-builder'
import { createEmotionCache } from '@/utils/emotion'
import { fetcher, onSwrError } from '@/utils/api-utils'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

Router.events.on('routeChangeStart', nProgress.start)
Router.events.on('routeChangeComplete', nProgress.done)
Router.events.on('routeChangeError', nProgress.done)

export interface IEnvoyWebAppProps extends AppProps {
  Component: NextPageWithLayout
  emotionCache: EmotionCache
}

export default function EnvoyWebApp(props: IEnvoyWebAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{APP_DISPLAY_NAME}</title>
        <meta name='viewport' content='initial-scale=1, width=device-width' />
      </Head>

      <SWRConfig value={{ fetcher, onError: onSwrError }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <SettingsConsumer>
              {({ settings }) => (
                <ThemeProvider
                  theme={createTheme({
                    responsiveFontSizes: settings.responsiveFontSizes,
                    mode: settings.theme
                  })}
                >
                  {/*
                    CssBaseline kickstart an elegant, consistent,
                    and simple baseline to build upon.
                  */}
                  <CssBaseline />
                  <Toaster position='top-center' />
                  {getLayout(<Component {...pageProps} />)}
                </ThemeProvider>
              )}
            </SettingsConsumer>
          </SettingsProvider>
        </LocalizationProvider>
      </SWRConfig>
    </CacheProvider>
  )
}
